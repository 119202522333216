import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { APP_AUTH_TOKEN } from '../../../utils/constants';
import BillingLayout from '../../../layouts/billing';

export default function SubscriptionSuccess() {
    return (
        <BillingLayout fullScreen>
            <Box
                display="flex"
                flexDirection='column'
                justifyContent="center"
                alignItems="center"
                minHeight="100vh"
            >
                <Box alignItems="center" display="flex" justifyContent="center">
                    <img src={require(`../../../assets/img/billing/check.png`)} alt="check" width="100" />
                </Box>

                <Box mb={6} mt={2}>
                    <Typography variant="h4" align="center">
                        Conta ativada com sucesso!
                    </Typography>
                </Box>

                <Grid item xs={12}>
                    <Box marginBottom={4}>
                        <Typography variant="body1" align="center">
                            Seja bem-vindo(a) à Mobilizei! Em breve você receberá um e-mail com os detalhes do pagamento.
                        </Typography>
                    </Box>

                    <Box marginBottom={8}>
                        <Typography variant="body1" align="center">
                            Clique no botão abaixo para acessar o nosso painel de controle e configurar o seu aplicativo.
                        </Typography>
                    </Box>
                </Grid>

                <Box alignItems="center" display="flex" justifyContent="center" m={8}>
                    <Button
                        color="primary"
                        disableElevation
                        onClick={() => window.location.href = `/?at=${window.localStorage.getItem(APP_AUTH_TOKEN)}`}
                        variant="contained"
                    >
                        Configurar aplicativo
                    </Button>
                </Box>
            </Box>
        </BillingLayout>
    );
}