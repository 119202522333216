export const APP_AUTH_TOKEN = 'app_auth_token'
export const APP_UTMS = 'app_utms'

export const PLATFORMS = {
    CONVERTR: 'convertr',
    TRAY: 'tray',
    NUVEMSHOP: 'nuvemshop',
    WIX: 'wix',
    WEBVIEW: 'webview'
}

export const appPublishStatus = {
    FIRST_PUBLISH_PENDING: "first_publish_pending",
    PENDING_LISTING_CREATION: "pending_listing_creation",
    DEVELOPER_ACCOUNT_PENDING: "developer_account_pending",
    PUBLISHING: "publishing",
    PENDING_GOOGLE_PLAY_DEVELOPER_ACCOUNT_VERIFICATION: "pending_google_play_developer_account_verification",
    PENDING_APPLE_ACCOUNT_RENEWAL: "pending_apple_account_renewal",
    PENDING_APPLE_ACCOUNT_TERMS_AGREEMENT: "pending_apple_account_terms_agreement",
    IN_REVIEW: "in_review",
    PUBLISHED: "published",
    PENDING_UPDATE: "pending_update"
}

export const onboardingSteps = {
    START: 'start',
    STORE_NAME: 'storeName',
    ECOMMERCE_PLATFORM: 'ecommercePlatform',
    ECOMMERCE_PLATFORM_INTEGRATION: 'ecommercePlatformIntegration',
    STORE_URL: 'storeUrl',
    ECOMMERCE_PLATFORM_NAME: 'ecommercePlatformName',
    STORE_CART_URL: 'storeCartUrl',
    STORE_ACCOUNT_URL: 'storeAccountUrl',
    CREATING_APP: 'creatingApp',
    PREVIEWER: 'previewer'
}

export const mixpanelEvents = {
    ONBOARDING_ECOMMERCE_PLATFORM_SELECTED: 'onboarding_ecommerce_platform_selected',
    ONBOARDING_STORE_URL_INSERTED: 'onboarding_store_url_inserted'
}

export const mobileAppPlatforms = {
    ANDROID: 'android',
    IOS: 'ios'
}

export const storeStatus = {
    ON_TRIAL: "on_trial",
    TRIAL_ENDED: "trial_ended",
    ACTIVE: "active",
    SUSPENDED: "suspended",
    ARCHIVED: "archived"
}

export const subscriptionStatus = {
    UNDEFINED: "undefined",
    PENDING: "pending",
    ACTIVE: "active",
    EXPIRED: "expired",
    PENDING_SUSPENSION: "pending_suspension",
    SUSPENDED: "suspended"
}

export const platformsOnboardingIntegration = [
    'nuvemshop',
    'wix'
]

export const platformsIntegration = [
    'tray'
]

export const planIds = {
    PLAN_BASIC_MONTHLY_2023: 'plano_basico_mensal_2023',
    PLAN_BASIC_YEARLY_2023: 'plano_basico_anual_2023',
    PLAN_PRO_MONTHLY_2023: 'plano_pro_mensal_2023',
    PLAN_PRO_YEARLY_2023: 'plano_pro_anual_2023',
    PLAN_BASIC_MONTHLY_2024: 'plano_basico_mensal_2024',
    PLAN_BASIC_YEARLY_2024: 'plano_basico_anual_2024',
    PLAN_PRO_MONTHLY_2024: 'plano_pro_mensal_2024',
    PLAN_PRO_YEARLY_2024: 'plano_pro_anual_2024',
    PLAN_EXCLUSIVE_MONTHLY_2023: 'plano_exclusivo_mensal_2023',
    PLAN_PRO_SMART_PUSH_2023: 'plano_pro_smart_push_2023',
    PLAN_PRO_SMART_PUSH_TRAY_2023: 'plano_pro_smart_push_tray_2023',
    PLAN_EXCLUSIVE_MONTHLY_VALUE_200: 'plano_exclusivo_mensal_valor_200',
    PLAN_EXCLUSIVE_MONTHLY_VALUE_300: 'plano_exclusivo_mensal_valor_300',
    PLAN_EXCLUSIVE_299_MONTHLY_2024: 'plano_exclusivo_299_mensal_2024',
}

export const planPeriodicity = {
    MONTHLY: "monthly",
    YEARLY: "yearly"
}

export const availablePlans = {
    basic: {
        monthly: "plano_basico_mensal_2024",
        yearly: "plano_basico_anual_2024",
    },
    pro: {
        monthly: "plano_pro_mensal_2024",
        yearly: "plano_pro_anual_2024",
    },
}

export const plans = {
    plan_initial: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_initial_annual: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 1069,
        periodicity: planPeriodicity.YEARLY,
    },
    plan_monthly_2022: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_annual_2022: {
        name: "Pro",
        isBasic: false,
        price: 99,
        totalPrice: 1068,
        periodicity: planPeriodicity.YEARLY,
    },
    plan_monthly_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 149,
        periodicity: planPeriodicity.MONTHLY,
    },
    plan_annual_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 1548,
        periodicity: planPeriodicity.YEARLY
    },
    plano_basico_mensal_2023: {
        name: "Básico",
        isBasic: true,
        price: 99,
        totalPrice: 99,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_basico_anual_2023: {
        name: "Básico",
        isBasic: true,
        price: 89,
        totalPrice: 1068,
        periodicity: planPeriodicity.YEARLY
    },
    plano_pro_mensal_2023: {
        name: "Pro",
        isBasic: false,
        price: 149,
        totalPrice: 149,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_anual_2023: {
        name: "Pro",
        isBasic: false,
        price: 129,
        totalPrice: 1548,
        periodicity: planPeriodicity.YEARLY
    },
    plano_basico_mensal_2024: {
        name: "Básico",
        isBasic: true,
        price: 129,
        totalPrice: 129,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_basico_anual_2024: {
        name: "Básico",
        isBasic: true,
        price: 109,
        totalPrice: 1308,
        periodicity: planPeriodicity.YEARLY
    },
    plano_pro_mensal_2024: {
        name: "Pro",
        isBasic: false,
        price: 199,
        totalPrice: 199,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_anual_2024: {
        name: "Pro",
        isBasic: false,
        price: 169,
        totalPrice: 2028,
        periodicity: planPeriodicity.YEARLY
    },
    plano_exclusivo_mensal_2023: {
        name: "Exclusivo",
        isBasic: false,
        price: 599,
        totalPrice: 599,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_smart_push_2023: {
        name: "Pro + Push Inteligente",
        isBasic: false,
        price: 299,
        totalPrice: 299,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_pro_smart_push_tray_2023: {
        name: "Pro + Push Inteligente",
        isBasic: false,
        price: 200,
        totalPrice: 200,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_mensal_valor_200: {
        name: "Exclusivo",
        isBasic: false,
        price: 200,
        totalPrice: 200,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_mensal_valor_300: {
        name: "Exclusivo",
        isBasic: false,
        price: 300,
        totalPrice: 300,
        periodicity: planPeriodicity.MONTHLY
    },
    plano_exclusivo_299_mensal_2024: {
        name: "Exclusivo",
        isBasic: false,
        price: 299,
        totalPrice: 299,
        periodicity: planPeriodicity.MONTHLY
    }
}

export const baseDeeplink = "https://mobilizei.onelink.me/omcI?af_xp=qr&pid=QR_code&af_dp=mobilizei%3A%2F%2F&af_force_deeplink=true"